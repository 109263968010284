import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AlertDialog } from '@homeexchange/design';

import i18n from '../../../i18n';

import Api from '../../../api/Api';

import Availability from '../../../models/Availability';
import HomeModel from '../../../models/Home';
import UserModel from '../../../models/User';

import Calendar from './ContactCalendar';
import MessageField from './MessageField';
import ExchangeTypeSelect from '../../widget/ExchangeTypeSelect';

import withAnalytics from '../../analytics/withAnalytics';
import CalendarUtils from '../../../utils/CalendarUtils';
import Utils from '../../../utils/utils';
import CustomGuestNb from '../../search/filters/CustomGuestNb';
import Analytics from '../../../utils/analytics';
import { setConversationsFetchConfig } from '@homeexchange/conversations';

export class MainView extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(UserModel).isRequired,
        home: PropTypes.instanceOf(HomeModel).isRequired,
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        onMessageSent: PropTypes.func.isRequired,
        track: PropTypes.func.isRequired,
        updateLastSearch: PropTypes.func.isRequired
    };

    static defaultProps = {
        availabilities: []
    };

    constructor(props) {
        super(props);

        this.state = {
            startOn: null,
            endOn: null,
            exchangeType: Availability.AVAILABLE.value,
            previousConversation: null,
            isCalendarOpen: false,
            disableSubmit: false,
            sending: false,
            openGuestNbPopup: false,
            guestsData: null,
            lastSearch: null,
            showAlertDialog: false,
            alertDialogProps: {}
        };

        this.invalidFields = [];

        this.datesChange = this.datesChange.bind(this);
        this.nbGuestChange = this.nbGuestChange.bind(this);
        this.setPreviouSearchData = this.setPreviouSearchData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.createConversation = this.createConversation.bind(this);
        this.onMessageSuccess = this.onMessageSuccess.bind(this);
        this.onMessageFailure = this.onMessageFailure.bind(this);
        this.exchangeTypeChange = this.exchangeTypeChange.bind(this);
        this.getExchangeTypeOnDateChange = this.getExchangeTypeOnDateChange.bind(this);
        this.openGuestNbPopup = this.openGuestNbPopup.bind(this);

        this.divRef = React.createRef();
        this.popupGuestNb = React.createRef();
        this.guestNb = React.createRef();
    }

    componentDidMount() {
        setConversationsFetchConfig(process.env.URL_BFF);

        const domain = Utils.getDomain();
        Utils.createCookie('access_token', window.gtg.accessToken, 1, domain);
        Utils.createCookie('refresh_token', window.gtg.refreshToken, 1, domain);

        this.getPreviousConversationForHome(this.props.home);
        this.loadDateRangeFromLocalLastSearch();
    }

    componentDidUpdate() {
        this.handleFormValidation();
    }

    // find a previous conversation between the two users for the same home
    getPreviousConversationForHome(home) {
        return Api.Conversation.me({ 'e.home': home.id }, 0, 1).then((response) => {
            if (response.conversations && response.conversations.length > 0) {
                this.setState({
                    previousConversation: _.first(response.conversations)
                });
            }
            return response;
        });
    }

    openGuestNbPopup(open) {
        const openGuestNbPopup = Boolean(open);
        if (openGuestNbPopup) {
            Utils.openModalsFullScreen();
        } else {
            Utils.closeModalsFullScreen();
        }
        this.setState({
            openGuestNbPopup
        });
    }

    // preset last date range based on user's last search
    loadDateRangeFromLocalLastSearch() {
        return Api.Search.getLocalLastSearch(this.props.user.id)
            .then((localResults) => {
                let start = null;
                let end = null;
                let guestsData = null;
                let exchangeType = Availability.AVAILABLE.value;

                if (_.isObject(localResults) && _.has(localResults, 'query')) {
                    if (_.has(localResults.query, 'calendar')) {
                        if (localResults.query.calendar.date_ranges?.length === 1) {
                            start = _.result(localResults.query.calendar.date_ranges[0], 'from', null);
                            end = _.result(localResults.query.calendar.date_ranges[0], 'to', null);
                        } else if (!localResults.query.calendar.date_ranges) {
                            Object.assign(localResults.query.calendar, {
                                date_ranges: [{}]
                            });
                        }

                        // Start date is in the past, remove dates
                        if (start && moment().isAfter(moment(start))) {
                            start = null;
                            end = null;
                        }

                        // compute exchangeType.
                        if (_.has(localResults.query.availability, 'reciprocal')) {
                            exchangeType = localResults.query.availability.reciprocal
                                ? Availability.RECIPROCAL.value
                                : Availability.NON_RECIPROCAL.value;
                        }
                    }

                    if (_.has(localResults.query, 'home')) {
                        guestsData = localResults.query.home;
                    }
                }

                this.setState({
                    lastSearch: localResults
                });

                return { start, end, guestsData, exchangeType };
            })
            .then(this.setPreviouSearchData);
    }

    // setPreviouSearchData
    setPreviouSearchData(lastSearchData) {
        if (lastSearchData.start && lastSearchData.end) {
            const range = moment.range(lastSearchData.start, lastSearchData.end);
            let setDates = !CalendarUtils.overlapsBookedPeriod(range, this.props.availabilities);
            if (
                setDates &&
                this.props.home.get('contact_allowed') === HomeModel.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE
            ) {
                // The user doesn't want to be contacted outside his available periods, check dates
                setDates = CalendarUtils.inAvailablePeriod(range, this.props.availabilities);
            }
            if (setDates && !this.state.startOn && !this.state.endOn) {
                // Check that the user hasn't updated the form already
                this.setState({
                    startOn: lastSearchData.start,
                    endOn: lastSearchData.end,
                    guestsData: lastSearchData.guestsData,
                    exchangeType: lastSearchData.exchangeType
                });
            } else if (!setDates) {
                this.setState({
                    guestsData: lastSearchData.guestsData,
                    exchangeType: lastSearchData.exchangeType
                });
            }
        } else {
            this.setState({
                guestsData: lastSearchData.guestsData,
                exchangeType: lastSearchData.exchangeType
            });
        }
    }

    handleFormValidation() {
        // handle form validation
        if (this.form) {
            for (let i = 0; i < this.form.elements.length; i++) {
                $(this.form.elements[i])
                    .off('invalid change blur keyup')
                    .on('invalid change blur keyup', (event) => {
                        const $target = $(event.target);
                        if ($target.hasClass('reset') || this.state.isCalendarOpen) {
                            // Do not validate reset button
                            return;
                        }

                        // disable default invalid bubble message
                        if (event.type === 'invalid') {
                            event.preventDefault();
                        }

                        const index = this.invalidFields.indexOf($target.get(0));
                        if (event.target.validity && !event.target.validity.valid) {
                            // There is an error
                            if (event.target.validity.valueMissing) {
                                $target
                                    .closest('.form-group')
                                    .addClass('has-error')
                                    .attr('data-error', i18n.t('common:validity_valueMissing'));
                            } else if (event.target.validationMessage) {
                                $target
                                    .closest('.form-group')
                                    .addClass('has-error')
                                    .attr('data-error', event.target.validationMessage);
                            }
                            if (index < 0) {
                                this.invalidFields.push($target.get(0));
                            }
                        } else {
                            if ($target.length > 0 && $target.attr('data-warning')) {
                                // Show warnings
                                $target
                                    .closest('.form-group')
                                    .addClass('has-error')
                                    .attr('data-error', $target.data('warning'));
                            } else {
                                $target
                                    .closest('.form-group')
                                    .removeClass('has-error')
                                    .removeAttr('data-error');
                            }

                            if (index >= 0) {
                                this.invalidFields.splice(index, 1);
                            }
                        }
                        // revalidate message if model changed
                        if (event.target.name === 'model') {
                            // use timeout to trigger change after component has updated the field
                            setTimeout(() => {
                                const $textarea = $target.closest('form').find('[name="content"]');
                                if ($textarea.val().toString().length > 0) {
                                    $textarea.trigger('change');
                                }
                            }, 100);
                        }

                        // Enable/Disable submit button if at least one field is invalid
                        if (this.invalidFields.length > 0) {
                            this.setState({
                                disableSubmit: true
                            });
                        } else {
                            this.setState({
                                disableSubmit: false
                            });
                        }
                    });
            }
        }
    }

    datesChange(dates) {
        if (_.isEmpty(dates)) {
            this.setState({
                numberOfNightsFromCalendar: 0
            });
            return;
        }
        const date1 = _.result(dates, 0, null);
        const date2 = _.result(dates, 1, null);
        const startOn = moment.isMoment(date1) ? date1.toDate() : date1;
        const endOn = moment.isMoment(date2) ? date2.toDate() : date2;
        const exchangeType = this.getExchangeTypeOnDateChange(startOn, endOn);
        const numberOfNightsFromCalendar = CalendarUtils.calculateNumberOfNightsOfSelectedDates(dates);
        this.setState({
            startOn,
            endOn,
            exchangeType,
            numberOfNightsFromCalendar
        });
    }

    nbGuestChange(adults, children, babies) {
        this.setState({
            guestsData: {
                ...this.state.guestsData,
                size: {
                    beds: {
                        adults: adults ? adults : null,
                        children: children ? children : null,
                        babies: babies ? babies : null
                    }
                }
            }
        });
    }

    exchangeTypeChange(event) {
        this.setState({
            exchangeType: event.target.value
        });
    }

    onSubmit(event) {
        event.preventDefault();

        this.form.checkValidity();

        const message = this.messageField.getMessage();

        // don't submit form if message is empty
        if (_.isEmpty(message) || this.state.disableSubmit) {
            return;
        }

        // Don't submit if user wants to be contacted on available dates only and no dates selected
        if (
            this.props.home.get('contact_allowed') === HomeModel.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE &&
            (!this.state.startOn || !this.state.endOn)
        ) {
            return;
        }

        this.setState({ sending: true });

        // Update last search properties to reflect what the user entered
        const search = this.state.lastSearch
            ? Utils.deepclone(this.state.lastSearch)
            : {
                  query: {
                      availability: { date_range: {} }
                  }
              };

        search.query = Object.assign(
            {
                availability: { date_range: {} }
            },
            search.query
        );

        // Update last search properties to reflect what the user entered
        if (this.state.startOn && this.state.endOn) {
            search.query.availability.date_range.from = moment(this.state.startOn).format('YYYY-MM-DD');
            search.query.availability.date_range.to = moment(this.state.endOn).format('YYYY-MM-DD');

            // Update exchange type
            if (this.state.exchangeType !== Availability.AVAILABLE.value) {
                search.query.availability.reciprocal =
                    parseInt(this.state.exchangeType, 10) === parseInt(Availability.RECIPROCAL.value, 10);
            }

            search.query.home = this.state.guestsData;
            this.props.updateLastSearch(search);
            Api.Search.setLastSearch(this.props.user.get('id'), search);
        }

        return this.createConversation();
    }

    async createConversation() {
        try {
            const message = this.messageField.getMessage();
            const response = await Api.Conversation.sendMessage({
                content: message,
                start_on: this.state.startOn ? moment(this.state.startOn).format('YYYY-MM-DD') : null,
                end_on: this.state.endOn ? moment(this.state.endOn).format('YYYY-MM-DD') : null,
                nb_guest: this.state.guestsData
                    ? _.reduce(this.state.guestsData.size.beds, (sum, elt) => sum + elt, 0)
                    : null,
                exchange_type: this.state.exchangeType,
                receiver: this.props.home.get('user').get('id'),
                home: this.props.home.get('id')
            });

            this.onMessageSuccess(response);
        } catch (error) {
            this.onMessageFailure(error);
        }
    }

    onMessageSuccess(response) {
        // total number of conversations created
        const nbConversations = response.nb_conv_created ? parseInt(response.nb_conv_created, 10) : null;

        // show success view
        const { user } = this.props;
        this.props.onMessageSent();

        /** @Tracking ConversationCreated */
        this.props.track('contacted-member', {
            conversationId: response.conversation.id
        });

        Analytics.trackGTM('MixpanelMessageSent', { userStatus: this.props.user.getStatus() });

        Analytics.trackGTM('conversation_created', {
            event_data: {
                exchange_type:
                    Number(this.state.exchangeType) === Availability.RECIPROCAL.value
                        ? 'reciprocal_exchange'
                        : 'guest_point_exchange',
                collection: user.isCollection()
            }
        });

        if (nbConversations === 1) {
            this.props.track('First message');
        }
    }

    onMessageFailure(response) {
        this.setState({ sending: false });

        if (response.responseJSON?.errors === 'No links allowed') {
            this.setState({
                showAlertDialog: true,
                alertDialogProps: {
                    title: 'Link Sending Not Allowed',
                    children:
                        'It looks like your message contains a link. For security reasons, sending links is not allowed.',
                    confirmActionText: 'Ok',
                    iconStatus: 'error',
                    onConfirmAction: () => {
                        this.setState({
                            showAlertDialog: false,
                            alertDialogProps: {}
                        });
                    }
                }
            });
            return;
        } else if (response.responseJSON?.errors === 'Rate limit exceeded') {
            this.setState({
                showAlertDialog: true,
                alertDialogProps: {
                    title: 'Sending limit reached temporarily',
                    children:
                        'You’ve just sent a message! To ensure fair usage and prevent spam, you’ll need to wait a moment before sending another.',
                    confirmActionText: 'Ok',
                    iconStatus: 'error',
                    onConfirmAction: () => {
                        this.setState({
                            showAlertDialog: false,
                            alertDialogProps: {}
                        });
                    }
                }
            });
            return;
        }

        let text = i18n.t('exchange:contact.error_message');
        if (
            response.responseJSON &&
            response.responseJSON.errors &&
            response.responseJSON.errors.match('does not belongs')
        ) {
            text = i18n.t('exchange:notperiod');
        }
        // dispay alert error message
        sweetAlert({
            icon: 'error',
            title: i18n.t('exchange:contact.title', {
                first_name: this.props.home.get('user').get('first_name')
            }),
            text
        });
    }

    getExchangeTypeOnDateChange(startOn, endOn) {
        if (!startOn || !endOn) {
            return Availability.AVAILABLE.value;
        }
        let exchangeType = 0;
        const exchangeDateRange = moment.range(startOn, endOn);
        const matchingAvailability = this.props.availabilities.find((av) =>
            av.range().contains(exchangeDateRange)
        );
        if (matchingAvailability) {
            const availability = matchingAvailability.get('type');
            exchangeType = Availability[availability].value;
        } else {
            exchangeType = Availability.AVAILABLE.value;
        }
        return exchangeType;
    }

    isMobile() {
        return 'matchMedia' in window && matchMedia('(max-width: 767px)').matches;
    }

    isExchangeNotCoveredBySubscription() {
        const { user } = this.props;
        const { endOn } = this.state;

        return Boolean(
            endOn &&
                user.isSubscriber() &&
                !user.get('subscription').auto_renew &&
                user.has('subscription_end_date') &&
                user.get('subscription_end_date') &&
                user.get('subscription_end_date').isBefore(Utils.getDayAtMidnightLocalTime(endOn))
        );
    }

    renderAlertDialog() {
        if (!this.state.showAlertDialog) {
            return null;
        }

        return <AlertDialog isOpen {...this.state.alertDialogProps} />;
    }

    render() {
        const { availabilities, home, user } = this.props;
        const { disableSubmit, previousConversation, sending, startOn, endOn, exchangeType, guestsData } =
            this.state;

        let { numberOfNightsFromCalendar } = this.state;
        const type = parseInt(exchangeType, 10);
        const btnSubmitClass = classNames('btn btn-primary btn-ajax', {
            disabled: disableSubmit,
            sending
        });
        const hostMinimumNumberOfNights = home.getMinNights();

        if (!numberOfNightsFromCalendar) {
            numberOfNightsFromCalendar = CalendarUtils.calculateNumberOfNightsOfSelectedDates([
                this.state.startOn,
                this.state.endOn
            ]);
        }
        return (
            <>
                {this.renderAlertDialog()}
                <section className="popup-contact">
                    <div className="row">
                        <main className="col-sm-12">
                            {(() => {
                                if (previousConversation) {
                                    return (
                                        <CSSTransitionGroup
                                            transitionName="alert-already-contacted"
                                            transitionAppear={true}
                                            transitionAppearTimeout={0}
                                            transitionEnterTimeout={500}
                                            transitionLeaveTimeout={300}
                                            component="div"
                                        >
                                            <div className="row">
                                                <div
                                                    className="alert alert-info alert-already-contacted"
                                                    role="alert"
                                                >
                                                    {i18n.t('exchange:contact.already_contacted', {
                                                        date: moment(
                                                            previousConversation.last_message.send_at
                                                        ).format('L')
                                                    })}
                                                    <a
                                                        href={i18n.t('exchange:conversation.url', {
                                                            id: previousConversation.id
                                                        })}
                                                        className="btn"
                                                    >
                                                        {i18n.t('exchange:see_conversation')}
                                                    </a>
                                                </div>
                                            </div>
                                        </CSSTransitionGroup>
                                    );
                                }
                            })()}
                            <form
                                action="#"
                                onSubmit={this.onSubmit}
                                ref={(form) => {
                                    this.form = form;
                                }}
                                noValidate
                            >
                                <header>
                                    <h1>
                                        {i18n.t('exchange:contact.title', {
                                            first_name: home.get('user').get('first_name')
                                        })}
                                    </h1>
                                </header>
                                <div className="popup-body">
                                    <div className="row popup-field-block">
                                        <div className="col-sm-12">
                                            <div>
                                                <p>{i18n.t('exchange:contact.dates_label')}</p>
                                            </div>
                                            <div className="form-groups-inline">
                                                <div className="form-group form-group-date-range">
                                                    <label
                                                        htmlFor="date_range"
                                                        className="sr-only"
                                                    >{`${i18n.t('search:filters.arrival')} -> ${i18n.t(
                                                        'search:filters.departure'
                                                    )}`}</label>
                                                    <Calendar
                                                        input={true}
                                                        mode="range"
                                                        required
                                                        nbMonths={this.isMobile() ? 1 : 2}
                                                        contactOnUnavailablePeriods={
                                                            home.get('contact_allowed') ===
                                                            HomeModel.CONTACT_ALLOWED_ON_UNAVAILABLE
                                                        }
                                                        startOn={startOn ? moment(startOn) : null}
                                                        endOn={endOn ? moment(endOn) : null}
                                                        selectedHandler={this.datesChange}
                                                        availabilities={availabilities}
                                                        classname={['contact-calendar']}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {numberOfNightsFromCalendar !== 0 &&
                                            numberOfNightsFromCalendar < hostMinimumNumberOfNights && (
                                                <div className="col-sm-12">
                                                    <div className="info-block">
                                                        <div className="info-block-container">
                                                            <div className="info-block-icon icon-min-nights"></div>
                                                        </div>
                                                        <div
                                                            className="info-block-description"
                                                            dangerouslySetInnerHTML={{
                                                                __html: i18n.t(
                                                                    'home:home_view_calendar_minimum_number_of_nights',
                                                                    {
                                                                        name: this.props.home
                                                                            .get('user')
                                                                            .get('first_name'),
                                                                        number: hostMinimumNumberOfNights
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    <div className="row popup-field-block">
                                        <div className="col-sm-12">
                                            <div>
                                                <p>{i18n.t('exchange:contact.nb_guests_label')}</p>
                                            </div>
                                            <div
                                                className="form-group form-group-guests-nb"
                                                ref={this.divRef}
                                            >
                                                <CustomGuestNb
                                                    divRef={this.divRef}
                                                    popupRef={this.popupGuestNb}
                                                    customGuestNbRef={this.guestNb}
                                                    open={this.state.openGuestNbPopup}
                                                    openGuestNb={this.openGuestNbPopup}
                                                    onChange={this.nbGuestChange}
                                                    home={guestsData}
                                                    showTitle={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row popup-field-block">
                                        <div className="col-sm-12">
                                            <div>
                                                <p>{i18n.t('exchange:contact.exchange_type_label')}</p>
                                            </div>
                                            <div className="form-group">
                                                <ExchangeTypeSelect
                                                    startOn={moment(startOn)}
                                                    endOn={moment(endOn)}
                                                    availabilities={availabilities}
                                                    exchangeType={type}
                                                    onChange={this.exchangeTypeChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.isExchangeNotCoveredBySubscription() && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="popup-contact-warning-subscription-expired">
                                                    {i18n.t('exchange:contact.warning.subscription.expired')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    <MessageField
                                        ref={(el) => {
                                            this.messageField = el;
                                        }}
                                        home={home}
                                        user={user}
                                        exchangeType={type}
                                    />
                                </div>
                                <footer className="popup-footer row">
                                    <div className="col-sm-12 text-right">
                                        <button
                                            type="submit"
                                            className={btnSubmitClass}
                                            disabled={disableSubmit}
                                        >
                                            {i18n.t('exchange:contact.send_request')}
                                        </button>
                                    </div>
                                </footer>
                            </form>
                        </main>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken
});

export default compose(connect(mapStateToProps), withAnalytics)(MainView);
